/* Add modern styles here */

body {
  font-family: "Comic Sans MS", Georgia, sans-serif;
  background-color: #ebe8e8;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: #3FC1AA;
}

.container {
  max-width: 960px;
  margin: 0 auto;
  padding: 20px;
}

h1 {
  font-size: 32px;
  margin-bottom: 30px;
  color: #333;
  text-align: center;
}

h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #555;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

li {
  margin-bottom: 10px;
  padding: 10px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.restaurant-name {
  font-weight: bold;
  color: #333;
}

.restaurant-cuisine {
  font-style: italic;
  color: #555;
}

.restaurant-address {
  color: #777;
}

.restaurant-sort-order {
  color: #aaa;
  font-size: 12px;
}

.restaurant-category {
  margin-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
}

.category-header {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.category-title {
  margin-right: 5px;
  background-color: #3FC1AA;
  padding: 10px;
  border-radius: 5px;
  color: #ffffff;
}

.category-toggle {
  font-weight: bold;
  font-size: 18px;
}

.category-content {
  list-style-type: none;
  padding-left: 20px;
  display: none;
}

.category-content.open {
  display: block;
}

.banner {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ebe8e8;
  padding: 20px;
  max-width: 768px;
  margin: 0 auto;
  overflow: hidden;
  padding-bottom: 10px;
}

.banner img {
  width: 100%;
  height: auto;
}

/* Adjust styles for smaller screens */
@media (max-width: 768px) {
  .banner {
    padding: 10px;
    max-width: 100%;
  }

  .header-container {
    flex-direction: column-reverse; /* Change flex-direction to column-reverse */
    align-items: center;
    text-align: center;
  }

  .header-container .search-input {
    margin-right: 0;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .header-container .city {
    padding-left: 0;
    text-align: left;
  }
}

.suburb-title {
  margin-right: 5px;
  background-color: #3FC1AA;
  padding: 10px;
  border-radius: 5px;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  color: #ffffff;
}

.suburb-toggle {
  font-weight: bold;
  font-size: 18px;
}

.suburb-content {
  list-style-type: none;
  padding-left: 20px;
}

.city {
  color: #3FC1AA;
  font-size: 24px;
  padding-left: 20px;
  margin: 0;
}

.app {
  max-width: 768px;
  margin: 0 auto;
}

.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.app h2.city {
  margin: 0;
}

.search-input {
  margin-right: 25px;
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  background-color: #f2f2f2;
  color: #333;
  font-size: 16px;
  width: 200px;
  transition: background-color 0.3s ease;
  &::placeholder {
    color: #3FC1AA;
  }
}

.search-input:focus {
  outline: none;
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  color: #3FC1AA;
}